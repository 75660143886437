import React from "react";
import Headline from "../../atoms/Headline";
import FAQ from "../FAQ";
import DrawTool from "../../organisms/DrawTool/container";

function Home() {
    return (
        <>
            <Headline>Draw random winner with RaffleNow</Headline>
            <p className="sm:w-3/4">
                You have started a competition or similar and would like to draw
                a random winner from all participants or entries? You've come to
                the right place. RaffleNow is all you need. Simply enter all
                participants in the following form, check if everything is fine
                and click on "Get winners" button. In a few seconds you will
                have your randomly selected winner(s).
            </p>
            <DrawTool />
            <FAQ />
        </>
    );
}

export default Home;

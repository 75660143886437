import React from "react";
import PropTypes from "prop-types";

function Headline({ children, level, className }) {
    const defaultStyles = "mb-4 mt-8 first:mt-0 font-bold";
    switch (level) {
        case 1:
            return (
                <h1 className={`${defaultStyles} text-8xl ${className}`}>
                    {children}
                </h1>
            );
        case 2:
            return (
                <h2 className={`${defaultStyles} text-6xl ${className}`}>
                    {children}
                </h2>
            );
        case 3:
            return (
                <h3 className={`${defaultStyles} text-2xl ${className}`}>
                    {children}
                </h3>
            );
        case 4:
            return (
                <h4 className={`${defaultStyles} text-xl ${className}`}>
                    {children}
                </h4>
            );
        case 5:
            return (
                <h5 className={`${defaultStyles} text-l ${className}`}>
                    {children}
                </h5>
            );
        default:
            return (
                <h1 className={`${defaultStyles} text-4xl ${className}`}>
                    {children}
                </h1>
            );
    }
}

Headline.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    level: PropTypes.number.isRequired,
};

Headline.defaultProps = {
    children: "",
    className: "",
    level: 1,
};

export default Headline;

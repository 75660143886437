import React, { useState } from "react";
import PropTypes from "prop-types";

function FormTextarea({ children, className, placeholder, onBlur }) {
    const [inputValue, setInputValue] = useState(children);

    const onInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const onInputBlur = (e) => {
        onBlur(inputValue);
    };

    return (
        <div className={className}>
            <textarea
                id="drawContent"
                name="drawContent"
                className={`px-5 sm:px-8 py-6 h-fit min-h-[400px] w-full rounded-3xl focus:shadow-xl text-primary-dark outline-0 transition mb-5 outline-none`}
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => onInputChange(e)}
                onBlur={(e) => onInputBlur(e)}
            />
        </div>
    );
}

FormTextarea.propTypes = {
    onBlur: PropTypes.func.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    placeholder: PropTypes.string,
};

FormTextarea.defaultProps = {
    children: "",
    className: "",
    placeholder: "",
};

export default FormTextarea;

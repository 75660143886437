import React from "react";
import Headline from "../../atoms/Headline";

function FAQ() {
    const FaqEntry = ({ head, text }) => {
        return (
            <div className="sm:w-2/5">
                <Headline level={4}>{head}</Headline>
                <p>{text}</p>
            </div>
        );
    };

    return (
        <section className="mt-20">
            <Headline level={2} className="text-center mb-8">
                Frequently Asked Questions
            </Headline>
            <div className="flex gap-10 flex-wrap justify-center">
                <FaqEntry
                    head="How to use RaffleNow?"
                    text="RaffleNow is a simple tool to draw your raffle. All you have to do is copy and paste all your raffle entries into the field provided and click on 'Check Entries'. In this step you will see all your entries. If everything is correct, you can click on the button 'Get the winner' to display your winners. A winner will be drawn at random from all remaining participants."
                />
                <FaqEntry
                    head="How to format participant list?"
                    text="Copy all your participants into the field provided. Each participant should be separated by a comma or a break so that RaffleNow recognises it as a single participant. For example, you can copy and paste an entire column from Excel into the field and click on the 'Check Entries' button."
                />
                <FaqEntry
                    head="Can I draw more than one winner?"
                    text="If you have more than one winner, for example if more than one prize is being raffled, then simply increase the value in the 'Winner' field. Simply click on the left and right arrows to increase the value. Alternatively, you can also enter the desired value in the field between the buttons."
                />
                <FaqEntry
                    head="How can I remove duplicate raffle participants?"
                    text="If there are several identical participants in the participant list, they will be sorted out if the 'Allow duplicates' checkbox is not activated. By default, the checkbox is activated, then the number of entries is displayed in the upper right corner of the respective participant in the next step. This makes sense if there were several opportunities to participate in the competition. It is important that the value entered is identical. This value is not case sensitive."
                />
            </div>
        </section>
    );
}

export default FAQ;

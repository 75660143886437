import React, { useState, useEffect } from "react";
import CookieConsent, {
    Cookies,
    getCookieConsentValue,
} from "react-cookie-consent";
import { Link } from "react-router-dom";
import { initGA } from "../../../util/googleAnalytics";

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isConsent, setIsConsent] = useState("NOT_SET");

    const buttonClasses = `px-4 py-2 w-full border-2 border-white font-semibold`;

    const handleAcceptCookie = () => {
        setIsVisible(false);
        if (process.env.REACT_APP_.GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_.GOOGLE_ANALYTICS_ID);
        }
    };

    const handleDeclineCookie = () => {
        setIsVisible(false);

        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    useEffect(() => {
        setIsConsent(getCookieConsentValue());
    }, []);

    useEffect(() => {
        if (isConsent === "true") {
            handleAcceptCookie();
        } else if (isConsent === "false" || isConsent === undefined) {
            setIsVisible(true);
        }
    }, [isConsent]);

    return (
        <div
            className={`${
                isVisible ? "block" : "hidden"
            } px-5 py-5 w-full bg-primary-default bottom-0 z-50 text-white border-t-8 border-white fixed`}
        >
            <div className="w-full max-w-5xl mx-auto flex gap-5 items-end flex-row sm:px-5">
                <CookieConsent
                    disableStyles
                    enableDeclineButton
                    style={{ display: "flex", gap: "0.5rem" }}
                    onAccept={() => handleAcceptCookie()}
                    onDecline={() => handleDeclineCookie()}
                    visible={isVisible}
                    buttonText="Sure"
                    declineButtonText="No"
                    expires={180}
                    buttonWrapperClasses={`flex gap-2 items-center flex-col sm:flex-row`}
                    buttonClasses={`${buttonClasses} bg-white text-primary-default`}
                    declineButtonClasses={`${buttonClasses}`}
                >
                    <span className="w-full">
                        In order to provide you with the best possible
                        experience, we store data in form of cookies on your
                        device. If you click on &quot;sure&quot; you allow us to
                        use also third partie tools to track performance of the
                        website, otherwise when choose &quot;no&quot; we only
                        using local data. More information can be found here:{" "}
                        <Link to="/dataprivacy">Data Privacy</Link>
                    </span>
                </CookieConsent>
            </div>
        </div>
    );
};

CookieBanner.propTypes = {};

CookieBanner.defaultProps = {};

export default CookieBanner;

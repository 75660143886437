import entriesReducer from "./entries";
import optionsReducer from "./options";
import { combineReducers } from "redux";

export default combineReducers({
    entries: entriesReducer,
    options: optionsReducer,
});

export const getRaffleInputs = (state) => state.entries;
export const getOptions = (state) => state.options;

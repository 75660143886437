import React from "react";
import Navigation from "../../molecules/Navigation";

function Header() {
    return (
        <header className="fixed top-0 left-0 w-full border-b-[1px] border-gray-300 py-3 bg-white z-50">
            <div className="mx-auto max-w-5xl px-5 flex justify-between items-baseline">
                <span className="font-semibold text-5xl sm:text-6xl border-b-8 mt-4 mb-3 border-primary-light leading-[20px]">
                    RaffleNow
                </span>
                <Navigation />
            </div>
        </header>
    );
}

export default Header;

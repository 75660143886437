import React from "react";
import Headline from "../../atoms/Headline";

function NoPage() {
    return (
        <>
            <Headline>404</Headline>
            <p>Content of 404 Page</p>
        </>
    );
}

export default NoPage;

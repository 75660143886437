import { connect } from "react-redux";
import DrawTool from "./DrawTool";
import {
    SAVE_RAFFLE_INPUTS,
    SAVE_RAFFLE_CONVERTED,
    SAVE_RAFFLE_DISPLAY,
    SAVE_RAFFLE_WINNER,
} from "../../../actions";
import { getRaffleInputs, getOptions } from "../../../reducers";

const mapStateToProps = (state) => {
    return {
        raffleInputs: getRaffleInputs(state).plain,
        raffleConverted: getRaffleInputs(state).converted,
        raffleDisplay: getRaffleInputs(state).display,
        raffleWinner: getRaffleInputs(state).winner,
        getOptions: getOptions(state),
    };
};

const saveRaffleInputs = (inputValue) => (dispatch, getState) => {
    dispatch({
        type: SAVE_RAFFLE_INPUTS,
        payload: inputValue,
    });
};

const saveRaffleConverted = (convertedValue) => (dispatch, getState) => {
    dispatch({
        type: SAVE_RAFFLE_CONVERTED,
        payload: convertedValue,
    });
};

const saveRaffleDisplay = (displayValue) => (dispatch, getState) => {
    dispatch({
        type: SAVE_RAFFLE_DISPLAY,
        payload: displayValue,
    });
};

const saveRaffleWinner = (winnerValue) => (dispatch, getState) => {
    dispatch({
        type: SAVE_RAFFLE_WINNER,
        payload: winnerValue,
    });
};

const mapDispatchToProps = {
    saveRaffleInputs,
    saveRaffleConverted,
    saveRaffleDisplay,
    saveRaffleWinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawTool);

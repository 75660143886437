import React from "react";
import PropTypes from "prop-types";
import Button from "../../atoms/Button/Button";

function FormButtonNavigation({
    backButtonLabel,
    submitButtonLabel,
    hideBackButton,
    onBackClick,
    onNextClick,
}) {
    return (
        <div className="flex justify-between">
            <div>
                {!hideBackButton && (
                    <Button
                        secondary
                        type="button"
                        onClick={(e) => onBackClick(e)}
                    >
                        {backButtonLabel}
                    </Button>
                )}
            </div>
            <div>
                <Button onClick={(e) => onNextClick(e)}>
                    {submitButtonLabel}
                </Button>
            </div>
        </div>
    );
}

FormButtonNavigation.propTypes = {
    backButtonLabel: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    hideBackButton: PropTypes.bool,
    onBackClick: PropTypes.func,
    onNextClick: PropTypes.func,
};

FormButtonNavigation.defaultProps = {
    backButtonLabel: "",
    submitButtonLabel: "",
    hideBackButton: false,
    onBackClick: () => {},
    onNextClick: () => {},
};

export default FormButtonNavigation;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Headline from "../../atoms/Headline";
import FormTextarea from "../../atoms/FormTextarea";
import Form from "../../atoms/Form/Form";
import FormButtonNavigation from "../../molecules/FormButtonNavigation/FormButtonNavigation";
import RaffleEntry from "../../atoms/RaffleEntry";
import FormRaffleOptions from "../../molecules/FormRaffleOptions/container";
import WinnerList from "../../molecules/WinnerList";
import ColorBox from "../../atoms/ColorBox";

function DrawTool({
    saveRaffleInputs,
    saveRaffleConverted,
    saveRaffleDisplay,
    saveRaffleWinner,
    raffleInputs,
    raffleConverted,
    raffleDisplay,
    raffleWinner,
    getOptions,
}) {
    const [currentStep, setCurrentStep] = useState(1);
    const scrolltoBoxRef = useRef();
    const stepHeadlines = [
        "Add your Participants",
        "Check your Raffle",
        getOptions.winnerAmount > 1
            ? "And the winners are..."
            : "And the winner is...",
    ];
    const stepDescription = [
        "Add all your raffle entries in the box below and set the options to suit your competition requirements. Once you have entered everything, click on the 'Check Entries' button.",
        "Please check all your competition entries to make sure they match the ones you have entered and that the options have been applied correctly. If you want to change anything, click on the 'Back' button. If everything is correct, click on the 'Get the winner' button to receive your randomly selected winners.",
        "",
    ];

    //scrollIntoView
    const scrollElementIntoView = (elem) => {
        var element = elem.current;
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    };

    // GetWinner
    const getWinner = () => {
        let participantsList = raffleConverted;
        let winnerAmount = getOptions.winnerAmount;
        let winnerList = [];

        for (let i = 0; i < winnerAmount; i++) {
            const drawnParticipant = Math.floor(
                Math.random() * participantsList.length
            );
            const winnerName = participantsList[drawnParticipant];

            // Add winner to winner list
            winnerList.push(winnerName);

            // Remove winner from participant list and its duplicates
            participantsList.splice(drawnParticipant, 1);
            participantsList = participantsList.filter((element) => {
                return element !== winnerName;
            });
        }

        saveRaffleWinner(winnerList);
    };

    // Convert for Step 2
    const convertEntries = (value) => {
        let replaceCharacters = value.split(/[,\n]+/);
        let convertEntries = replaceCharacters.map((entry) => {
            return entry.trim().toLowerCase();
        });

        // Remove empty entries
        convertEntries = convertEntries.filter((entry) => entry);

        // Remove duplicates if not allowed
        if (!getOptions.duplicate) {
            convertEntries = [...new Set(convertEntries)];
        }

        saveRaffleConverted(convertEntries);
        generateDisplayEntries(convertEntries);
    };

    // Generate Entries to display
    const generateDisplayEntries = (value) => {
        // Check if duplicates are available and combine it in one for display purposes
        let displayEntries = value.map((entry) => {
            let duplicateEntries = value.filter((element) => {
                return element === entry;
            });
            return {
                name: entry,
                entries: duplicateEntries.length,
            };
        });

        // Filter duplicate value in array
        displayEntries = displayEntries.filter(
            (entry, index, self) =>
                self.findIndex(
                    (t) => t.place === entry.place && t.name === entry.name
                ) === index
        );

        saveRaffleDisplay(displayEntries);
    };

    // On submit Step 1
    const onFormSubmitStepOne = (e) => {
        e.preventDefault();
        scrollElementIntoView(scrolltoBoxRef);
        if (raffleInputs) {
            convertEntries(raffleInputs);
            setCurrentStep(2);
        }
    };

    // On submit Step 2
    const onFormSubmitStepTwo = (e) => {
        e.preventDefault();
        scrollElementIntoView(scrolltoBoxRef);
        getWinner();
        setCurrentStep(currentStep + 1);
    };

    // get content from textarea and split
    const onTextareaBlur = (value) => {
        saveRaffleInputs(value);
    };

    // Reset App
    const resetApp = (e) => {
        e.preventDefault();

        setCurrentStep(1);
        saveRaffleInputs("");
        saveRaffleConverted([]);
        saveRaffleDisplay([]);
        saveRaffleWinner([]);
        scrollElementIntoView(scrolltoBoxRef);
    };

    return (
        <>
            <ColorBox ref={scrolltoBoxRef}>
                <Headline level={2}>{stepHeadlines[currentStep - 1]}</Headline>
                {stepDescription[currentStep - 1] && (
                    <p className="sm:w-2/3 mb-6">
                        {stepDescription[currentStep - 1]}
                    </p>
                )}
                {currentStep === 1 && (
                    <Form onSubmit={(e) => onFormSubmitStepOne(e)}>
                        <FormRaffleOptions />
                        <FormTextarea
                            onBlur={onTextareaBlur}
                            placeholder="Paste all your participants into this field. All entries must be separated by comma or new line break."
                        >
                            {raffleInputs}
                        </FormTextarea>
                        <FormButtonNavigation
                            hideBackButton
                            submitButtonLabel="Check Entries"
                        />
                    </Form>
                )}
                {currentStep === 2 && (
                    <>
                        <FormRaffleOptions readOnly />
                        <Headline level={5} className="mb-2">
                            Participants
                        </Headline>
                        <div className="flex flex-wrap gap-3 mb-10">
                            {raffleDisplay.map((entry, index) => (
                                <RaffleEntry
                                    key={index}
                                    entries={entry.entries}
                                >
                                    {entry.name}
                                </RaffleEntry>
                            ))}
                        </div>
                        <FormButtonNavigation
                            backButtonLabel="Back"
                            onBackClick={() => setCurrentStep(currentStep - 1)}
                            onNextClick={(e) => onFormSubmitStepTwo(e)}
                            submitButtonLabel="Get Winner"
                        />
                    </>
                )}
                {currentStep === 3 && (
                    <>
                        <WinnerList list={raffleWinner} />
                        <p className="text-center">
                            Winners drawn by{" "}
                            <span className="font-bold">RaffleNow.org</span>
                        </p>
                    </>
                )}
            </ColorBox>
            <p className="text-center mt-2 text-sm">
                <span
                    onClick={(e) => resetApp(e)}
                    className="text-primary-default border-b-[1px] border-primary-default hover:cursor-pointer hover:text-primary-dark hover:border-primary-dark"
                >
                    Click here
                </span>{" "}
                to reset the application and start a new raffle.
            </p>
        </>
    );
}

DrawTool.propTypes = {
    saveRaffleInputs: PropTypes.func.isRequired,
    saveRaffleConverted: PropTypes.func.isRequired,
    saveRaffleDisplay: PropTypes.func.isRequired,
    saveRaffleWinner: PropTypes.func.isRequired,
    raffleInputs: PropTypes.string,
    raffleConverted: PropTypes.array,
    raffleDisplay: PropTypes.array,
    raffleWinner: PropTypes.array,
    getOptions: PropTypes.object.isRequired,
};

DrawTool.defaultProps = {
    raffleInputs: "",
    raffleConverted: [],
    raffleDisplay: [],
    raffleWinner: [],
};

export default DrawTool;

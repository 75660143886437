import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../ToolTip/ToolTip";

function FormNumber({
    id,
    className,
    value,
    onChange,
    children,
    tooltipContent,
}) {
    const buttonClasses = "text-primary-default text-xl px-2";
    const onInputChange = (e) => {
        onChange(Number(e.target.value));
    };

    const onButtonClick = (e, action) => {
        e.preventDefault();

        switch (action) {
            case "down":
                onChange(value > 1 ? value - 1 : 1);
                break;
            case "up":
                onChange(value + 1);
                break;
            default:
                break;
        }
    };

    return (
        <div className={`flex gap-2 items-center ${className}`}>
            <div className="px-1 py-1 rounded-2xl bg-white items-center">
                <button
                    className={buttonClasses}
                    onClick={(e) => onButtonClick(e, "down")}
                >
                    ❰
                </button>
                <input
                    type="number"
                    id={id}
                    name={children}
                    className={`text-center text-primary-dark outline-0 transition outline-none w-10 m-0 text-lg px-2`}
                    value={value}
                    onChange={(e) => onInputChange(e)}
                    min={1}
                />
                <button
                    className={buttonClasses}
                    onClick={(e) => onButtonClick(e, "up")}
                >
                    ❱
                </button>
            </div>
            <label htmlFor={id}>{children}</label>
            {tooltipContent && <ToolTip>{tooltipContent}</ToolTip>}
        </div>
    );
}

FormNumber.propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.number,
    children: PropTypes.string,
    tooltipContent: PropTypes.string,
};

FormNumber.defaultProps = {
    value: 1,
    className: "",
    children: "",
    tooltipContent: "",
};

export default FormNumber;

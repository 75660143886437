import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import party, { Color } from "party-js";
import LoadingIndicator from "../../atoms/LoadingIndicator";

function WinnerList({ list }) {
    const winnerlistRef = useRef();
    const [showWinner, setShowWinner] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowWinner(true);
        }, 4000);

        if (showWinner) {
            party.confetti(winnerlistRef.current, {
                count: party.variation.range(100, 120),
                color: Color.fromHex("43b0f1"),
            });
        }
    }, [showWinner]);

    return (
        <>
            {!showWinner ? (
                <LoadingIndicator
                    textAbove={
                        list.length > 1
                            ? "The winners will be chosen..."
                            : "The winner will be chosen..."
                    }
                />
            ) : (
                <ul
                    className="font-bold text-5xl text-center uppercase mb-8 sm:w-3/4 mx-auto mt-8 px-2"
                    ref={winnerlistRef}
                >
                    {list.map(
                        (winner, index) =>
                            winner && (
                                <li
                                    className="bg-white text-primary-default p-8 mb-2 animate-appearFromAbove rounded-2xl relative"
                                    key={index}
                                    style={{
                                        animationDelay: 0.5 * index + "s",
                                    }}
                                >
                                    {list.length > 1 && (
                                        <span className="absolute top-1/2 left-0 -translate-y-1/2 -translate-x-4 w-9 h-9 leading-[28px] bg-white rounded-full border-[4px] border-primary-default text-xl text-center">
                                            {index + 1}
                                        </span>
                                    )}

                                    {winner}
                                </li>
                            )
                    )}
                </ul>
            )}
        </>
    );
}

WinnerList.propTypes = {
    list: PropTypes.array.isRequired,
};

export default WinnerList;

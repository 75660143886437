import React from "react";
import PropTypes from "prop-types";
import FormCheckbox from "../../atoms/FormCheckbox";
import FormNumber from "../../atoms/FormNumber";
import Headline from "../../atoms/Headline";

function FormRaffleOptions({
    optionDuplicates,
    optionWinnerAmount,
    updateOptionDuplicates,
    updateOptionWinnerAmount,
    readOnly,
}) {
    return (
        <div className="mb-8">
            <Headline level={5} className="mb-2">
                Options
            </Headline>
            <div className="flex gap-5 flex-wrap items-center">
                {readOnly ? (
                    <>
                        <p className="m-0">
                            {optionDuplicates
                                ? "Duplicates are allowed"
                                : "Dulicates are not allowed"}
                        </p>
                        <p className="flex gap-1 m-0">
                            <span className="bg-white px-3 text-primary-default rounded-md">
                                {optionWinnerAmount}
                            </span>
                            <span>
                                {optionWinnerAmount > 1 ? "Winners" : "Winner"}
                            </span>
                        </p>
                    </>
                ) : (
                    <>
                        <FormCheckbox
                            id="option-duplicates"
                            checked={optionDuplicates}
                            onChange={(e) => updateOptionDuplicates(e)}
                            tooltipContent="If participants can enter your raffle in different ways or plattforms, this checkbox should be active. If everyone is only allowed with one entry this checkbox should not be active."
                        >
                            Allow duplicates
                        </FormCheckbox>
                        <FormNumber
                            id="winnerAmount"
                            onChange={(e) => updateOptionWinnerAmount(e)}
                            value={optionWinnerAmount}
                            tooltipContent="If you have more prices to draw, increase the amount of winners."
                        >
                            Winner
                        </FormNumber>
                    </>
                )}
            </div>
        </div>
    );
}

FormRaffleOptions.propTypes = {
    updateOptionDuplicates: PropTypes.func.isRequired,
    updateOptionWinnerAmount: PropTypes.func.isRequired,
    optionDuplicates: PropTypes.bool.isRequired,
    optionWinnerAmount: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
};

FormRaffleOptions.defaultProps = {
    readOnly: false,
};

export default FormRaffleOptions;

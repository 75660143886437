import React from "react";
import PropTypes from "prop-types";

const Button = ({
    children,
    onClick,
    className,
    secondary,
    type,
    disabled,
    name,
}) => {
    return (
        <button
            type={type}
            className={`px-8 py-4 border-2 border-white transition font-bold shadow-md rounded-lg  hover:border-primary-bright ${
                secondary
                    ? "bg-primary-default hover:text-primary-bright hover:bg-primary-light"
                    : "bg-white text-primary-default hover:text-primary-dark hover:bg-primary-bright"
            } ${className}`}
            onClick={(e) => onClick(e)}
            disabled={disabled}
            aria-label={name}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
};

Button.defaultProps = {
    children: "",
    className: "",
    secondary: false,
    disabled: false,
    type: "submit",
    name: "",
    onClick: () => {},
};

export default Button;

import React, { useState } from "react";
import PropTypes from "prop-types";
import ToolTip from "../ToolTip/ToolTip";

function FormCheckbox({ id, children, checked, onChange, tooltipContent }) {
    const [isChecked, setIsChecked] = useState(checked);

    const onInputChange = (e) => {
        setIsChecked(!isChecked);
        onChange(!isChecked);
    };

    return (
        <div className="flex gap-2 relative items-center">
            <div
                className={`w-5 h-5 border-[4px] border-white rounded-md transition ${
                    checked ? "bg-primary-default" : "bg-white"
                }`}
                onClick={(e) => onInputChange(e)}
            />
            <input
                id={id}
                type="checkbox"
                className={`opacity-0 w-0 h-0 absolute`}
                onChange={(e) => onInputChange(e)}
                checked={checked}
            />
            <label htmlFor={id} className="leading-2">
                {children}
            </label>
            {tooltipContent && <ToolTip>{tooltipContent}</ToolTip>}
        </div>
    );
}

FormCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.any,
    checked: PropTypes.bool,
    tooltipContent: PropTypes.string,
};

FormCheckbox.defaultProps = {
    children: "",
    checked: false,
    tooltipContent: "",
};

export default FormCheckbox;

import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "../reducers";

const configStore = () => {
    const isDevelopment = process.env.NODE_ENV !== "production";

    const composeEnhancers =
        (isDevelopment &&
            typeof window !== "undefined" &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    return createStore(
        reducer,
        undefined,
        composeEnhancers(applyMiddleware(thunk.withExtraArgument()))
    );
};

export default configStore;

import React from "react";
import PropTypes from "prop-types";

const ColorBox = React.forwardRef(({ children, className }, ref) => {
    return (
        <section
            ref={ref}
            className={`bg-primary-default px-5 pt-8 pb-8 sm:px-8 sm:pt-10 sm:pb-12 mt-12 rounded-[40px] text-white ${className}`}
        >
            {children}
        </section>
    );
});

ColorBox.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

ColorBox.defaultProps = {
    children: "",
    className: "",
};

export default ColorBox;

import {
    UPDATE_OPTION_DUPLICATE,
    UPDATE_OPTION_WINNER_AMOUNT,
} from "../../actions";

const reducer = (state = { duplicate: true, winnerAmount: 1 }, action) => {
    switch (action.type) {
        case UPDATE_OPTION_DUPLICATE:
            return {
                ...state,
                duplicate: action.payload,
            };
        case UPDATE_OPTION_WINNER_AMOUNT:
            return {
                ...state,
                winnerAmount: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;

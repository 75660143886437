import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import configStore from "./store";
import "./styles/default.css";
import AppWrapper from "./components/pages/AppWrapper/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={configStore()}>
        <React.StrictMode>
            <AppWrapper />
        </React.StrictMode>
    </Provider>
);

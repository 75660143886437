import React from "react";
import PropTypes from "prop-types";

const RaffleEntry = ({ children, entries }) => {
    return (
        <div className="px-5 py-3 b-white rounded-2xl bg-white text-primary-dark uppercase relative font-bold">
            {children}
            {entries > 1 && (
                <span className="absolute text-xs w-5 h-5 text-center leading-[14px] -top-2 -right-2 bg-red-600 p-1 rounded-full text-white font-semibold">
                    {entries}
                </span>
            )}
        </div>
    );
};

RaffleEntry.propTypes = {
    children: PropTypes.any,
    entries: PropTypes.number,
};

RaffleEntry.defaultProps = {
    children: "",
    entries: 1,
};

export default RaffleEntry;

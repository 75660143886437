import React from "react";
import Navigation from "../../molecules/Navigation";

function Footer() {
    return (
        <footer className="mt-20 text-xs border-t-2 bg-primary-default pt-4 pb-20 text-white font-semibold">
            <div className="mx-auto max-w-5xl px-5 flex justify-between items-baseline">
                <p>
                    Copyright by{" "}
                    <a
                        href="https://herrnerd.de/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Herr Nerd
                    </a>
                </p>
                <Navigation isFooter />
            </div>
        </footer>
    );
}

export default Footer;

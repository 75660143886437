import React from "react";
import Headline from "../../atoms/Headline";

function Imprint() {
    return (
        <>
            <Headline level={1}>Imprint</Headline>
            <Headline level={2}>Angaben gemäß § 5 TMG</Headline>{" "}
            <p>
                Christoph Köckerling
                <br /> Käthe-Kollwitz-Str. 3<br /> 41569 Rommerskirchen
            </p>
            <Headline level={2}>Kontakt:</Headline>
            <p>mail@rafflenow.org</p>
            <Headline level={2}>
                Verantwortlich für den Inhalt nach § 55 ABS. 2 RSTV:
            </Headline>
            <p>
                Christoph Köckerling
                <br /> Käthe-Kollwitz-Str. 3<br /> 41569 Rommerskirchen
            </p>
            <p>
                Quelle:{" "}
                <a
                    href="http://www.e-recht24.de"
                    target="_blank"
                    rel="noreferrer"
                >
                    http://www.e-recht24.de
                </a>
            </p>
            <Headline level={2}>Haftung für Links</Headline>
            <p>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
            </p>
            <Headline level={2}>Urheberrecht</Headline>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
            </p>
            <p>
                RaffleNow.org verwendet offizielle Artworks, Grafiken und
                Screenshot und damit urheberrechtlich geschützte Werke. Die
                aktuell gültigen Kennzeichen- und Besitzrechte unterliegen dem
                Eigentümer und/oder etwaigen Tochter- oder Drittfirmen. Ihnen
                bleiben alle Rechte vorbehalten. Eine Vervielfältigung oder
                Verwendung dieses Bildes oder dieser Grafik in anderen
                elektronischen oder gedruckten Publikationen ist ohne
                ausdrückliche Zustimmung des Rechtsinhabers nicht gestattet.
            </p>
        </>
    );
}

export default Imprint;

import React from "react";
import styles from "./LoadingIndicator.module.css";
import PropTypes from "prop-types";

const LoadingIndicator = ({ textAbove }) => {
    return (
        <div className="my-20">
            {textAbove && <p className="text-center font-bold">{textAbove}</p>}
            <div className={`${styles.progress} bg-primary-light`}>
                <div className={`${styles.progressColor} bg-primary-normal`} />
            </div>
        </div>
    );
};

LoadingIndicator.propTypes = {
    textAbove: PropTypes.string,
};

LoadingIndicator.defaultProps = {
    textAbove: "",
};

export default LoadingIndicator;

import {
    SAVE_RAFFLE_INPUTS,
    SAVE_RAFFLE_CONVERTED,
    SAVE_RAFFLE_DISPLAY,
    SAVE_RAFFLE_WINNER,
} from "../../actions";

const reducer = (
    state = { plain: "", converted: [], display: [], winner: [] },
    action
) => {
    switch (action.type) {
        case SAVE_RAFFLE_INPUTS:
            return {
                ...state,
                plain: action.payload,
            };
        case SAVE_RAFFLE_CONVERTED:
            return {
                ...state,
                converted: action.payload,
            };
        case SAVE_RAFFLE_DISPLAY:
            return {
                ...state,
                display: action.payload,
            };
        case SAVE_RAFFLE_WINNER:
            return {
                ...state,
                winner: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;

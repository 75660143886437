import React from "react";
import { Outlet } from "react-router-dom";

function ContentWrapper() {
    return (
        <main className="mt-32 sm:mt-40 mx-auto max-w-5xl w-full px-5">
            <Outlet />
        </main>
    );
}

export default ContentWrapper;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Navigation({ isFooter }) {
    return (
        <nav>
            <ul
                className={`flex ${
                    isFooter
                        ? "gap-2"
                        : "gap-4 text-base sm:text-lg font-semibold"
                }`}
            >
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/impressum">Imprint</Link>
                </li>
                {isFooter && (
                    <li>
                        <Link to="/dataprivacy">Data Privacy</Link>
                    </li>
                )}
            </ul>
        </nav>
    );
}

Navigation.propTypes = {
    isFooter: PropTypes.bool,
};

Navigation.defaultProps = {
    isFooter: false,
};

export default Navigation;

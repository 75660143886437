import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../../organisms/Header";
import Footer from "../../organisms/Footer";
import ContentWrapper from "../../atoms/ContentWrapper/ContentWrapper";
import Imprint from "../Imprint";
import DataPrivacy from "../DataPrivacy";
import Home from "../Home";
import NoPage from "../NoPage";
import CookieBanner from "../../atoms/CookieBanner";

function AppWrapper() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<ContentWrapper />}>
                    <Route index element={<Home />} />
                    <Route path="impressum" element={<Imprint />} />
                    <Route path="dataprivacy" element={<DataPrivacy />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
            <Footer />
            <CookieBanner />
        </BrowserRouter>
    );
}

export default AppWrapper;

import React from "react";
import PropTypes from "prop-types";

const Form = ({ children, onSubmit, className }) => {
    return (
        <form
            className={`w-full p-0 m-0 ${className}`}
            onSubmit={(e) => onSubmit(e)}
        >
            {children}
        </form>
    );
};

Form.propTypes = {
    children: PropTypes.any,
    onSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
};

Form.defaultProps = {
    children: "",
    className: "",
    onSubmit: () => {},
};

export default Form;

import { connect } from "react-redux";
import FormRaffleOptions from "./FormRaffleOptions";
import {
    UPDATE_OPTION_DUPLICATE,
    UPDATE_OPTION_WINNER_AMOUNT,
} from "../../../actions";
import { getOptions } from "../../../reducers";

const mapStateToProps = (state) => {
    return {
        optionDuplicates: getOptions(state).duplicate,
        optionWinnerAmount: getOptions(state).winnerAmount,
    };
};

const updateOptionDuplicates = (inputValue) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_OPTION_DUPLICATE,
        payload: inputValue,
    });
};

const updateOptionWinnerAmount = (inputValue) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_OPTION_WINNER_AMOUNT,
        payload: inputValue,
    });
};

const mapDispatchToProps = { updateOptionDuplicates, updateOptionWinnerAmount };

export default connect(mapStateToProps, mapDispatchToProps)(FormRaffleOptions);

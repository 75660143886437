import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

function ToolTip({ children, symbol }) {
    const tooltipWidthDefault = 300;
    const tooltipLeftPositonDefault = 0;
    const [isActive, setIsActive] = useState(false);
    const [tooltipWidth, setTooltipWidth] = useState(tooltipWidthDefault);
    const [tooltipLeftPosition, setTooltipLeftPosition] = useState(0);
    const tooltipRef = useRef();
    const triggerRef = useRef();

    // set ToolTip Position
    const setToolTipPosition = useCallback(() => {
        if (isActive) {
            const outerPadding = 20;
            const windowWidth = window.innerWidth;
            const toolTipRect = tooltipRef.current.getBoundingClientRect();
            const triggerRect = triggerRef.current.getBoundingClientRect();

            // Check if tooltip is out on the right
            if (windowWidth - outerPadding + 1 < toolTipRect.right) {
                setTooltipLeftPosition(windowWidth - toolTipRect.right);
            } else {
                setTooltipLeftPosition(tooltipLeftPositonDefault);
            }

            // Check if tooltip is too big
            if (windowWidth - outerPadding * 2 < toolTipRect.width) {
                setTooltipWidth(windowWidth - outerPadding * 2);
            } else {
                setTooltipWidth(tooltipWidthDefault);
            }
        }
    }, [isActive]);

    const onClickTooltip = () => {
        setIsActive(!isActive);
        setToolTipPosition();
    };

    useEffect(() => {
        setToolTipPosition();

        // Close tool tip when clicked outside
        document.addEventListener("click", (e) => {
            if (
                e.target !== tooltipRef.current &&
                e.target !== triggerRef.current
            ) {
                setIsActive(false);
            }
        });

        window.onresize = (e) => {
            if (isActive) {
                //setToolTipPosition();
            }
        };
    }, [isActive, setToolTipPosition]);

    return (
        <div className={`relative`}>
            <span
                ref={triggerRef}
                className="w-4 h-4 border-[1px] border-white text-white text-xs rounded-full text-center hover:cursor-pointer block leading-1"
                onClick={() => onClickTooltip()}
            >
                {symbol}
                <span
                    className={`absolute -top-1 left-1/2 rotate-45 -translate-y-full -translate-x-1/2 w-2 h-2 bg-white z-10 ${
                        isActive ? "block" : "hidden"
                    }`}
                ></span>
            </span>
            {isActive && (
                <div
                    ref={tooltipRef}
                    className={`absolute bg-white text-sm text-primary-dark px-2 py-2 rounded shadow-lg -top-2 -translate-x-1/2 -translate-y-full`}
                    style={{
                        width: tooltipWidth + "px",
                        left: tooltipLeftPosition,
                    }}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

ToolTip.propTypes = {
    symbol: PropTypes.string,
    children: PropTypes.any,
};

ToolTip.defaultProps = {
    symbol: "?",
    children: "",
};

export default ToolTip;
